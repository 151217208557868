import useCities from "./use-cities"

function getTopAreasOverview(cities) {
  const objCounts = {
    improve_internal_operations: [0, 0, 0],
    create_cost_savings: [0, 0, 0],
    anticipate_future_challenges: [0, 0, 0],
    service_current_obligations: [0, 0, 0],
    improve_service_delivery: [0, 0, 0],
    improve_resident_outcomes: [0, 0, 0],
    simplify_admin_procedures: [0, 0, 0],
    generate_new_revenue_sources: [0, 0, 0],
    engage_stakeholders: [0, 0, 0],
  }

  for (const city of cities) {
    if (city && city.section_top_areas_data) {
      for (const key in city.section_top_areas_data) {
        const rank = city.section_top_areas_data[key]
        if (rank) objCounts[key][rank - 1] += 1
      }
    }
  }
  return objCounts
}

function getLackingFactorsOverview(cities) {
  const objCounts = {
    dedicated_funding: 0,
    strong_focus_on_data: 0,
    a_strong_team: 0,
    human_resources: 0,
    leadership_commitment: 0,
    culture_of_innovation: 0,
    engagement_with_partners: 0,
    support_from_broader_community: 0,
  }

  for (const city of cities) {
    if (city && city.section_lacking_factors_data) {
      for (const key in city.section_lacking_factors_data) {
        objCounts[key] += city.section_lacking_factors_data[key] ? 1 : 0
      }
    }
  }

  const result = {}
  for (const key in objCounts) {
    result[key] = parseInt((objCounts[key] / cities.length) * 100 * 100) / 100
  }

  return result
}

function getTopChallengesOverview(cities) {
  const objCounts = {
    lack_of_reliable_data: [0, 0, 0],
    public_distrust_of_government_data: [0, 0, 0],
    lack_of_staff_capacity: [0, 0, 0],
    lack_of_technical_infrastructure: [0, 0, 0],
    lack_of_compatible_data: [0, 0, 0],
    data_are_not_shared: [0, 0, 0],
    insufficient_interaction_with_data: [0, 0, 0],
    data_priority: [0, 0, 0],
  }

  for (const city of cities) {
    if (city && city.section_top_challenges_data) {
      for (const key in city.section_top_challenges_data) {
        const rank = city.section_top_challenges_data[key]
        if (rank) objCounts[key][rank - 1] += 1
      }
    }
  }

  const result = {}
  for (const key in objCounts) {
    let categoryTotalVotes =
      objCounts[key][0] + objCounts[key][1] + objCounts[key][2]
    result[key] = [
      parseInt((objCounts[key][0] / categoryTotalVotes) * 100 * 100) / 100,
      parseInt((objCounts[key][1] / categoryTotalVotes) * 100 * 100) / 100,
      parseInt((objCounts[key][2] / categoryTotalVotes) * 100 * 100) / 100,
    ]
  }

  return result
}

function getCurrentVsPlannedOverview(cities) {
  const objCounts = {
    advertising: [0, 0],
    stuff: [0, 0],
    data_work_decision_making: [0, 0],
    outcome_evaluation: [0, 0],
  }

  let voteCountCurrent = 0
  let voteCountPlanned = 0

  for (const city of cities) {
    if (city && city.section_current_vs_planned_data) {
      if (city.section_current_vs_planned_data.current) {
        voteCountCurrent += 1
        for (const key in city.section_current_vs_planned_data.current) {
          if (city.section_current_vs_planned_data.current[key])
            objCounts[key][0] += 1
        }
      }
      if (city.section_current_vs_planned_data.planned) {
        voteCountPlanned += 1
        for (const key in city.section_current_vs_planned_data.planned) {
          if (city.section_current_vs_planned_data.planned[key])
            objCounts[key][1] += 1
        }
      }
    }
  }

  const res = {}

  for (const key in objCounts) {
    res[key] = [
      parseInt((objCounts[key][0] / voteCountCurrent) * 100 * 100) / 100,
      parseInt((objCounts[key][1] / voteCountPlanned) * 100 * 100) / 100,
    ]
  }

  return res
}

function getBudgetPlansOverview(cities) {
  let cityVoteCount = 0
  let votes = cities.reduce(
    (acc, city) => {
      if (city.section_budget_plans_data != undefined) {
        cityVoteCount += 1
        acc[city.section_budget_plans_data - 1] += 1
      }
      return acc
    },
    [0, 0, 0, 0, 0]
  )

  return votes.map(c => parseInt((c / cityVoteCount) * 100 * 100) / 100)
}

function getSatisfactionOverview(cities) {
  let totalVoteCount = 0
  let objCountsTrue = {
    innovation_staff: 0,
    holistic_approach: 0,
    formal_innovation_strategy: 0,
  }
  let objCountsFalse = {
    innovation_staff: 0,
    holistic_approach: 0,
    formal_innovation_strategy: 0,
  }

  for (const city of cities) {
    if (city.section_satisfaction_data) {
      totalVoteCount += 1
      if (city.section_satisfaction_data.innovation_staff)
        objCountsTrue.innovation_staff +=
          city.section_satisfaction_data.percent_satisfied
      else
        objCountsFalse.innovation_staff +=
          city.section_satisfaction_data.percent_satisfied
      if (city.section_satisfaction_data.holistic_approach)
        objCountsTrue.holistic_approach +=
          city.section_satisfaction_data.percent_satisfied
      else
        objCountsFalse.holistic_approach +=
          city.section_satisfaction_data.percent_satisfied
      if (city.section_satisfaction_data.formal_innovation_strategy)
        objCountsTrue.formal_innovation_strategy +=
          city.section_satisfaction_data.percent_satisfied
      else
        objCountsFalse.formal_innovation_strategy +=
          city.section_satisfaction_data.percent_satisfied
    }
  }

  return {
    innovation_staff: [
      objCountsTrue.innovation_staff / totalVoteCount,
      objCountsFalse.innovation_staff / totalVoteCount,
    ],
    holistic_approach: [
      objCountsTrue.holistic_approach / totalVoteCount,
      objCountsFalse.holistic_approach / totalVoteCount,
    ],
    formal_innovation_strategy: [
      objCountsTrue.formal_innovation_strategy / totalVoteCount,
      objCountsFalse.formal_innovation_strategy / totalVoteCount,
    ],
  }
}

function getWalkabilityOverview(cities) {
  let usCityVoteCount = [0, 0]
  let euCityVoteCount = [0, 0]

  let objCounts = {
    walkability: [0, 0],
    transport_satisfaction: [0, 0],
  }

  for (const city of cities) {
    if (city.summary.country_name == "United States") {
      if (
        city.section_walkability_data &&
        city.section_walkability_data.walkability
      ) {
        usCityVoteCount[city.section_walkability_data.psi_capacity - 1] += 1
        objCounts.walkability[city.section_walkability_data.psi_capacity - 1] +=
          city.section_walkability_data.walkability
      }
    } else {
      if (
        city.section_walkability_data &&
        city.section_walkability_data.transport_satisfaction
      ) {
        euCityVoteCount[city.section_walkability_data.psi_capacity - 1] += 1
        objCounts.transport_satisfaction[
          city.section_walkability_data.psi_capacity - 1
        ] += city.section_walkability_data.transport_satisfaction
      }
    }
  }

  return {
    walkability: objCounts.walkability.map(
      (el, psi) => el / usCityVoteCount[psi]
    ),
    transport_satisfaction: objCounts.transport_satisfaction.map(
      (el, psi) => el / euCityVoteCount[psi]
    ),
  }
}

function getStakeholdersOverview(cities) {
  const counts = {
    stakeholder: {
      sum: [0, 0, 0],
      count: [0, 0, 0],
    },
    openData: {
      sum: [0, 0, 0],
      count: [0, 0, 0],
    },
  }

  for (const city of cities) {
    if (
      city.section_stakeholder_data &&
      city.section_stakeholder_data.open_data_level &&
      city.section_stakeholder_data.stakeholder_level &&
      city.section_stakeholder_data.percent_households
    ) {
      counts.stakeholder.sum[
        city.section_stakeholder_data.stakeholder_level - 1
      ] += city.section_stakeholder_data.percent_households
      counts.stakeholder.count[
        city.section_stakeholder_data.stakeholder_level - 1
      ] += 1

      counts.openData.sum[city.section_stakeholder_data.open_data_level - 1] +=
        city.section_stakeholder_data.percent_households
      counts.openData.count[
        city.section_stakeholder_data.open_data_level - 1
      ] += 1
    }
  }


  return {
    stakeholder: [
      counts.stakeholder.sum[0] / counts.stakeholder.count[0],
      counts.stakeholder.sum[1] / counts.stakeholder.count[1],
      counts.stakeholder.sum[2] / counts.stakeholder.count[2],
    ],
    openData: [
      counts.openData.sum[0] / counts.openData.count[0],
      counts.openData.sum[1] / counts.openData.count[1],
      counts.openData.sum[2] / counts.openData.count[2],
    ],
  }
}

function useChartData() {
  const cities = useCities()

  const result = {}
  result.topAreas = getTopAreasOverview(cities)
  result.lackingFactors = getLackingFactorsOverview(cities)
  result.topChallenges = getTopChallengesOverview(cities)
  result.currentVsPlanned = getCurrentVsPlannedOverview(cities)
  result.budgetPlans = getBudgetPlansOverview(cities)
  result.satisfaction = getSatisfactionOverview(cities)
  result.walkability = getWalkabilityOverview(cities)
  result.stakeholders = getStakeholdersOverview(cities)
  return result
}

export default useChartData
